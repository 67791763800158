import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import SEO from '../new/components/SEO';
import ContactFormModule from '../new/modules/ContactFormModule';
import PageHeaderModule from '../new/modules/PageHeaderModule';

const Page404: FC<PageProps> = () => {
  return (
    <>
      <SEO title="404" />
      <PageHeaderModule
        title="404 - Sorry, we couldn't find that page!"
        variant="xxl"
      />
      <ContactFormModule
        form_data={'7dfec556-192c-483f-bc39-246e44bb1f7b'}
        tagline={'READY TO GET STARTED?'}
        title={'Transform how your most important work is delivered'}
        subtitle={
          'Meet the team, discuss your ideas with our experts and receive a proposal for your project.'
        }
      />
    </>
  );
};

export default Page404;
